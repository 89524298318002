import React from 'react';

function Contact() {
  return (
    <section id="contact">
      <h2>Kontakt</h2>
      <p>Find os her og kontakt os gerne.</p>
      <address>
        <p>Adresse: Strandstien 1A, 3740 Svaneke</p>
        <p>Telefon: 24 83 27 71</p>
        <p>Email: <a href="mailto:info@bayfrostcafe.dk">info@bayfrostcafe.dk</a></p>
      </address>
      {/* Indsæt kort og yderligere information for turister her */}
    </section>
  );
}

export default Contact;
