// src/components/Menu.js
import React, { useEffect } from 'react';
import { initMenuAnimations } from '../animations';
import Separator from './Separator';
import drinkBackground from '../assets/drinkbaggrund.jpg'; // Ensure correct import

// Drinks
import drinkImage1 from '../assets/anden-background.jpg';
import drinkImage2 from '../assets/drink2.jpg';
import drinkImage3 from '../assets/drink3.jpg';
import drinkImage4 from '../assets/drink4.jpg';
import drinkImage5 from '../assets/drink5.jpg';
import drinkImage6 from '../assets/drink6.jpg';

// Kage
import chokoladeKage from '../assets/chokoladeKage.jpg';
import drømmeKage from '../assets/drømmeKage.jpg';

// Sandwich
import skinkeSandwich from '../assets/Skinke Sandwich.jpg';
import kebabSandwich from '../assets/Kebab Sandwich.jpg';

function Menu() {
  useEffect(() => {
    initMenuAnimations();
  }, []);

  return (
    <section id="menu">
      <Separator title="Menu" backgroundImage={drinkBackground} offset={200}/>
      <div className="menu-category">
        <h3>Drinks</h3>
        <div className="menu-grid">
          <div className="menu-item">
            <img src={drinkImage1} alt="Drink 1" />
            <div className="menu-info">
              <h4>Drink 1</h4>
              <p>Beskrivelse af drink 1.</p>
              <span className="price">45 DKK</span>
            </div>
          </div>
          <div className="menu-item">
            <img src={drinkImage2} alt="Drink 2" />
            <div className="menu-info">
              <h4>Drink 2</h4>
              <p>Beskrivelse af drink 1.</p>
              <span className="price">45 DKK</span>
            </div>
          </div>
          <div className="menu-item">
            <img src={drinkImage3} alt="Drink 3" />
            <div className="menu-info">
              <h4>Drink 3</h4>
              <p>Beskrivelse af drink 1.</p>
              <span className="price">45 DKK</span>
            </div>
          </div>
          <div className="menu-item">
            <img src={drinkImage4} alt="Drink 4" />
            <div className="menu-info">
              <h4>Drink 4</h4>
              <p>Beskrivelse af drink 1.</p>
              <span className="price">45 DKK</span>
            </div>
          </div>
          <div className="menu-item">
            <img src={drinkImage5} alt="Drink 5" />
            <div className="menu-info">
              <h4>Drink 5</h4>
              <p>Beskrivelse af drink 1.</p>
              <span className="price">45 DKK</span>
            </div>
          </div>
          <div className="menu-item">
            <img src={drinkImage6} alt="Drink 6" />
            <div className="menu-info">
              <h4>Drink 6</h4>
              <p>Beskrivelse af drink 1.</p>
              <span className="price">45 DKK</span>
            </div>
          </div>
        </div>
      </div>

      <div className="menu-category">
        <div className="menu-category-title">
          <h3>Kager</h3>
        </div>
        <div className="menu-grid">
          <div className="menu-item">
            <img src={chokoladeKage} alt="Chokolade Kage" />
            <div className="menu-info">
              <h4>Chokolade Kage</h4>
              <p>Beskrivelse af kage 1.</p>
              <span className="price">45 DKK</span>
            </div>
          </div>
          <div className="menu-item">
            <img src={drømmeKage} alt="Drømme Kage" />
            <div className="menu-info">
              <h4>Drømme Kage</h4>
              <p>Beskrivelse af kage 1.</p>
              <span className="price">45 DKK</span>
            </div>
          </div>
        </div>
      </div>

      <div className="menu-category">
        <div className="menu-category-title">
          <h3>Frokost</h3>
        </div>
        <div className="menu-grid">
          <div className="menu-item">
            <img src={skinkeSandwich} alt="Skinke Sandwich" />
            <div className="menu-info">
              <h4>Skinke Sandwich</h4>
              <p>Beskrivelse af frokost 1.</p>
              <span className="price">45 DKK</span>
            </div>
          </div>
          <div className="menu-item">
            <img src={kebabSandwich} alt="Kebab Sandwich" />
            <div className="menu-info">
              <h4>Kebab Sandwich</h4>
              <p>Beskrivelse af frokost 1.</p>
              <span className="price">45 DKK</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Menu;
