import React from 'react';
import Separator from './Separator';

import galleri3 from '../assets/galleri3.jpg';

function About() {
  return (
    <section id="about" className="about-section">
      <Separator title="Om os" backgroundImage={galleri3} offset={900}/>
      <h2>Om os</h2>
      <p>Lorem ipsum dolor sit amet. Cum neque possimus vel facilis voluptate ut placeat suscipit et dolor temporibus. Id internos fugit eum tempora sint et nemo voluptatum qui neque excepturi aut consectetur ipsam ut veniam veritatis aut veniam aperiam. Et pariatur doloribus est molestiae beatae eos suscipit impedit vel dicta nobis? </p>
      <p>Qui minima mollitia et sunt sequi ea officiis nesciunt a dignissimos porro. Sit voluptatem quas est natus velit est quos odio ea accusantium amet et velit omnis. Id iste modi eos ipsa delectus eum aliquid sapiente! </p>
      <p>Id consequuntur officiis qui illo quod eos consequatur dolorum non amet dolores est quidem natus aut autem minima. Et consequuntur provident sed deleniti amet in veniam consequuntur ut atque ducimus. Ut magnam ducimus non quasi culpa qui ipsam enim est architecto asperiores ut corrupti soluta? Est tempora quia aut reiciendis cupiditate ex voluptatem ipsam?</p>
      {/* Indsæt mere information her */}
    </section>
  );
}

export default About;
