// animations.js
import { gsap } from "gsap";

export function initHeroSectionAnimation() {
  const button = document.querySelector('.cta-button');
  if (button) {
    gsap.fromTo(button, { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: 1, ease: 'power3.out' });
  }
}

export function initGalleryAnimations() {
  const items = document.querySelectorAll('.gallery-item');

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        gsap.fromTo(entry.target, { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: 1, ease: 'power3.out', stagger: 0.2 });
        observer.unobserve(entry.target); // Unobserve after animation
      }
    });
  });

  items.forEach(item => {
    observer.observe(item);
  });
}

export function initMenuAnimations() {
  const items = document.querySelectorAll('.menu-item');

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        gsap.fromTo(entry.target, { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: 1, ease: 'power3.out', stagger: 0.2 });
        observer.unobserve(entry.target); // Unobserve after animation
      }
    });
  });

  items.forEach(item => {
    observer.observe(item);
  });
}
