function updateParallax() {
  const parallaxElements = document.querySelectorAll('.separator');
  const heroImage = document.querySelector('.hero-image');

  parallaxElements.forEach((element) => {
    const speed = element.getAttribute('data-speed');
    const offsetValue = element.getAttribute('data-offset');
    const windowHeight = window.innerHeight;
    
    // Adjust speed based on viewport height
    const adjustedSpeed = speed * (windowHeight / 1000);

    if (window.innerWidth > 768) {
      const offset = window.pageYOffset * adjustedSpeed;
      element.style.backgroundPositionY = `calc(50% + ${offset}px + ${offsetValue}px)`;
    } else {
      element.style.backgroundPositionY = 'center';
    }
  });

  // Add the scroll effect for the circular hero image
  if (heroImage) {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition > 0) {
      heroImage.style.transform = `translateX(${scrollPosition * -0.2}px)`;
    } else {
      heroImage.style.transform = 'translateX(0)';
    }
  }
}

// Update parallax on scroll
window.addEventListener('scroll', updateParallax);

// Update parallax on resize
window.addEventListener('resize', updateParallax);

// Initial call to set parallax position
updateParallax();
