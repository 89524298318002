import React from 'react';
import './App.css';
import HeroSection from './components/HeroSection';
import Menu from './components/Menu';
import About from './components/About';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import './Parallax'; // Add this line to import the parallax script

function App() {
  return (
    <div className="App">
      <Navbar />
      <div id="home"><HeroSection /></div>
      <div id="menu"><Menu /></div>
      <div id="about"><About /></div>
      <div id="gallery"><Gallery /></div>
      <div id="contact"><Contact /></div>
      <Footer />
    </div>
  );
}

export default App;
