import React, { useEffect } from 'react';
import { initGalleryAnimations } from '../animations'; // Ensure you have this function in your animations file
import Separator from './Separator';

import galleri1 from '../assets/galleri1.jpg';
import galleri2 from '../assets/galleri2.jpg';
import galleri3 from '../assets/galleri3.jpg';
import galleri4 from '../assets/galleri4.jpg';
import galleri5 from '../assets/galleri5.jpg';

function Gallery() {
  useEffect(() => {
    initGalleryAnimations();
  }, []);

  return (
    <section id="gallery">
      <Separator title="Galleri" backgroundImage={galleri1} offset={-600} />

      <h2>Galleri</h2>
      <p>Se billeder af vores café og retter.</p>
      <div className="gallery-grid">
        <img src={galleri1} alt="Galleri 1" className="gallery-item" />
        <img src={galleri2} alt="Galleri 2" className="gallery-item" />
        <img src={galleri3} alt="Galleri 3" className="gallery-item" />
        <img src={galleri4} alt="Galleri 4" className="gallery-item" />
        <img src={galleri5} alt="Galleri 5" className="gallery-item" />
      </div>
    </section>
  );
}

export default Gallery;
