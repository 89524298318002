import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import logo from '../assets/BayFrostIcon.png'; // Original logo
import hoverLogo from '../assets/BayFrostIconGlow.png'; // Hover logo

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo" onClick={refreshPage} style={{ cursor: 'pointer' }}>
          <img src={logo} alt="Logo" />
          <img src={hoverLogo} alt="Logo Hover" className="hover-image" />
        </div>
        <div className="menu-toggle" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <ul className={`menu ${menuOpen ? 'open' : ''}`}>
          <li><ScrollLink to="home" smooth={true} duration={500} offset={-60}>Home</ScrollLink></li>
          <li><ScrollLink to="menu" smooth={true} duration={500} offset={-60}>Menu</ScrollLink></li>
          <li><ScrollLink to="about" smooth={true} duration={500} offset={-60}>About</ScrollLink></li>
          <li><ScrollLink to="gallery" smooth={true} duration={500} offset={-60}>Gallery</ScrollLink></li>
          <li><ScrollLink to="contact" smooth={true} duration={500} offset={-60}>Contact</ScrollLink></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
