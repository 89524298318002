import React from 'react';
import '../App.css';

const Separator = ({ title, backgroundImage, offset }) => {
  return (
    <div className="separator" style={{ backgroundImage: `url(${backgroundImage})` }} data-speed="-.5" data-offset={offset}>
      <div className="separator-overlay"></div>
      <h3>{title}</h3>
    </div>
  );
};

export default Separator;
