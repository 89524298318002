// src/components/HeroSection.js
import React, { useEffect } from 'react';
import { initHeroSectionAnimation } from '../animations';
import '../App.css';
import heroImage from '../assets/anden-background.jpg'; // Ensure correct import path

function HeroSection() {
  useEffect(() => {
    initHeroSectionAnimation();
  }, []);

  return (
    <div className="hero" style={{ marginTop: '60px' }}>
      <div className="hero-image-container">
        <img src={heroImage} alt="Bay Frost Café" className="hero-image" />
        <div className="hero-text">
          <h1>Velkommen til Bay Frost Café</h1>
          <p>Nyd vores dejlige mad og drikkevarer i en simpel og lys atmosfære.</p>
          <a href="#menu" className="cta-button">Se vores Menu</a>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
